import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useCallback,
} from "react";
import { Helmet } from "react-helmet";

import { CountUp } from "countup.js";
import { Column } from "@ant-design/charts";

import Playlist from "../components/Playlist";
import RankingTable from "../components/RankingTable";
import ListenScene from "../components/ListenScene";
import PeriodList from "../components/PeriodList";
import {
  fetchListeningList,
  fetchPlayCountTopList,
  fetchPlayingCount,
  fetchLocationList,
  fetchUATopList,
  fetchPeriodList,
  fetchCoreCity,
  fetchListenScene,
} from "./service";
import {
  SApp,
  SBlockWrapper,
  SBlock,
  SHeader,
  SHeaderTip,
  SHeaderCount,
  SHeaderCountWrapper,
  SName,
  SIcon,
  SNote,
  SCanvasbox,
} from "./style";

const Analytics = () => {
  const [locationList, setLocationList] = useState<LocationTop[]>([]);
  const [uaList, setUAList] = useState<UserAgent[]>([]);
  const [listeningList, setListeningList] = useState<PlayingItem[]>([]);
  const [playCountList, setPlayCountList] = useState<PlayingItem[]>([]);
  const [periodList, setPeriodList] = useState<ResPeriod[]>([]);
  const [listenScene, setListenScene] = useState<ResListenScene>();
  const [coreCity, setCoreCity] = useState<ResCoreCity[]>([]);

  const countRef = useRef(0);
  const countPCRef = useRef(0);
  const countMobileRef = useRef(0);
  const countOtherRef = useRef(0);

  const getPlayingCount = () => {
    fetchPlayingCount().then((data) => {
      [
        ["#total-count", data.total, countRef],
        ["#mobile-count", data.mobile, countMobileRef],
        ["#pc-count", data.desktop, countPCRef],
        ["#other-count", data.other, countOtherRef],
      ].forEach((item) => {
        const id = item[0] as string;
        const value = item[1] as number;
        const ref = item[2] as MutableRefObject<number>;
        const targetEle = document.querySelector(id);
        if (targetEle) {
          const countUp = new CountUp(targetEle as HTMLElement, value, {
            startVal: ref.current,
            duration: Math.abs(value - ref.current) / 100,
          });
          countUp.reset();
          countUp.start();
        }
        ref.current = value;
      });
    });
  };

  const getListeningList = useCallback(() => {
    let response_hash = "";
    fetchListeningList().then((data) => {
      // Initial
      setListeningList(data.result.slice(0, 16));
      response_hash = data.response_hash;

      setInterval(() => {
        fetchListeningList(response_hash).then((data) => {
          response_hash = data.response_hash;

          setListeningList((state) => {
            return data.result
              .slice(0, 16)
              .concat(
                state.filter((_, index) => index < 16 - data.result.length)
              );
          });
        });
      }, 20000);
    });
  }, []);

  let intervalRef = useRef(-1);
  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.hidden) {
          clearInterval(intervalRef.current);
        } else {
          intervalRef.current = setInterval(getPlayingCount, 5000);
        }
      },
      false
    );

    getPlayingCount();
    intervalRef.current = setInterval(getPlayingCount, 5000);

    fetchLocationList().then((data) => {
      setLocationList(data.result);
    });

    fetchUATopList().then((data) => {
      setUAList(data.result.slice(0, 15));
    });

    getListeningList();
    fetchPlayCountTopList().then((data) => {
      setPlayCountList(data.result.slice(0, 10));
    });

    fetchPeriodList().then((data) => {
      setPeriodList(data.result);
    });

    fetchListenScene().then((data) => {
      setListenScene(data);
    });

    fetchCoreCity().then((data) => {
      setCoreCity(data.result.slice(0, 20));
    });
  }, [getListeningList]);

  return (
    <SApp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>实时数据 - 津津乐道播客</title>
      </Helmet>
      <SHeader>
        <SHeaderTip
          style={{
            opacity: 0.7,
            fontWeight: "bold",
            fontSize: 20,
          }}
        >
          正在收听
        </SHeaderTip>
        <SHeaderCountWrapper>
          <SHeaderCount id="total-count"></SHeaderCount>位
        </SHeaderCountWrapper>
        <SHeaderTip style={{ marginBottom: 56 }}>
          听友正在收听津津乐道播客网络的节目
        </SHeaderTip>
        <div style={{ width: "100%", textAlign: "center" }}>
          <SHeaderTip icon="device">
            移动端 <span id="mobile-count"></span>
          </SHeaderTip>
          <SHeaderTip icon="computer">
            电脑 <span id="pc-count"></span>
          </SHeaderTip>
          <SHeaderTip icon="microphone">
            其它 <span id="other-count"></span>
          </SHeaderTip>
        </div>
      </SHeader>
      <SBlockWrapper>
        <SBlock className="locationList">
          <SName>收听位置排行榜</SName>
          <SIcon src="/icons/location.svg" alt="收听位置排行榜" />
          <RankingTable
            columns={2}
            list={locationList as any[]}
            tip="*基于最近 30 天收听数据"
          />
        </SBlock>
        <SBlock className="ualist">
          <SName>客户端排行榜</SName>
          <SIcon src="/icons/mobile2.svg" alt="客户端排行榜" />
          <RankingTable list={uaList as any[]} tip="*基于最近 7 天收听数据" />
        </SBlock>
        <SBlock className="coreCity">
          <SName>主要城市收听人数排行榜（每万人）</SName>
          <SCanvasbox>
            <Column
              padding={[20, 20, 40, 20]}
              data={coreCity}
              xField="city"
              yField="market_share"
              meta={{
                city: { alias: "城市" },
                market_share: { alias: "人数（每万人）" },
              }}
              xAxis={{ label: { autoRotate: false } }}
              scrollbar={{ type: "horizontal" }}
              label={{
                position: "top",
                offsetY: 8,
                style: { fill: "#333333", opacity: "0.6" },
              }}
            />
          </SCanvasbox>
          <SNote>*收听人数基于过去30天收听数据得出</SNote>
        </SBlock>
        <SBlock className="listenScene">
          <ListenScene
            name="收听场景分布"
            tip="*分布数据基于过去30天收听数据得出"
            data={listenScene}
          />
        </SBlock>
        <SBlock className="listeningList">
          <Playlist
            name="大家正在听"
            icon="microphone2"
            list={listeningList}
            tip="*基于数据抽样显示，并非 1:1 展示"
          />
        </SBlock>
        <SBlock className="periodList">
          <PeriodList
            name="收听时段分布"
            list={periodList}
            tip="*分布数据基于过去24小时收听数据得出。如8点的数据为1%，则意味着在过去24小时中，有1%的听众在8点至9点之间下载或收听了节目"
          />
        </SBlock>
        <SBlock className="playCountList">
          <Playlist
            name="七日收听排行榜"
            icon="analytics"
            list={playCountList}
            tip="*基于最近 7 天收听数据"
          />
        </SBlock>
      </SBlockWrapper>
      <span className="footer">
        本页面数据由津津乐道播客网络通过泛用型播客客户端的收听数据统计得出
        <br />
        <br />
        由于算法原因，本页面提供的实时数据仅供参考，如需获得更详尽的监播数据，请联系：{" "}
        <a href="mailto:pr@dao.fm" target="_blank" rel="noopener noreferrer">
          pr@dao.fm
        </a>{" "}
        索取
        <br />
        <br />
        本页面 IP 数据服务由{" "}
        <a
          href="https://www.ipip.net/"
          target="_blank"
          rel="noopener noreferrer"
        >
          IPIP.net
        </a>{" "}
        提供
      </span>
    </SApp>
  );
};

export default Analytics;
