import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Users() {
  let [phone, setPhone] = useState("");
  let [captchaImg, setCaptchaImg] = useState("");
  let [captcha, setCaptcha] = useState("");
  let query = useQuery();
  if (!query.get("state")) {
    let url = window.location.href;
    let urlText = encodeURIComponent(url);
    window.location.href = `https://apis.daopub.com/v2/user/wechat_authorize?scope=snsapi_userinfo&redirect_uri=${urlText}`;
  }
  if (!captchaImg && !captcha) {
    fetch("https://apis.daopub.com/v2/captcha/create?width=300&height=100")
      .then((res) => res.json())
      .then((res) => {
        setCaptchaImg(res.image_url);
        setCaptcha(res.captcha_token);
      });
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>用户注册 - 津津乐道播客</title>
      </Helmet>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-32 w-auto"
            src="https://postimg.aliavv.com/mbp2021/6sotu.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            注册你的账户
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  手机号
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="text"
                    type="text"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    autoComplete="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  验证码
                </label>
                <div className="mt-1">
                  <img
                    className="mx-auto h-12 w-auto"
                    src={captchaImg}
                    alt="captcha"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  图片验证码
                </label>
                <div className="mt-1">
                  <input
                    id="text"
                    name="text"
                    type="text"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="mb-3 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  获取短信验证码
                </button>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  手机验证码
                </label>
                <div className="mt-1">
                  <input
                    id="text"
                    name="text"
                    type="text"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  登录
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Users;
