import React from "react";

import {
  SPlaylistIcon,
  SPlaylistName,
  SPlaylistMeta,
  SPlaylist,
  SEpisode,
  SCover,
  STitle,
  SMeta,
  SMetaItem,
  SNote,
} from "./style";
import { DEFAULT_DAO_LOGO, DEFAULT_DAO_URL } from "../../constants";

type Props = {
  name: string;
  icon?: string;
  list: PlayingItem[];
  tip?: string;
};

const Playlist: React.FC<Props> = ({ name, icon, list = [], tip }) => {
  // TODO: 完善对照关系
  const getIcon = (device_type = "") => {
    switch (device_type) {
      case "iPhone":
      case "Apple Watch":
      case "iPad":
        return "apple";
      case "Android":
        return "android";
      case "Windows":
        return "windows";
      default:
        return "feed";
    }
  };

  return (
    <>
      <SPlaylistMeta>
        <SPlaylistName>{name}</SPlaylistName>
        {icon && <SPlaylistIcon src={`/icons/${icon}.svg`} alt={name} />}
      </SPlaylistMeta>
      <SPlaylist>
        {(list || []).map((item, index) => {
          let device = item.user_agent?.os_type;

          if (
            item.user_agent?.device_type &&
            item.user_agent.device_type !== "Unknow"
          ) {
            device = item.user_agent.device_type;
          }

          if (["Unknow", "Unknown"].includes(device!)) {
            device = "";
          }

          return (
            <SEpisode
              key={item.episode_id + index}
              href={item.episode.post_url || DEFAULT_DAO_URL}
              target="_blank"
              title={device}
            >
              <SCover
                src={item.episode.itunes_image || DEFAULT_DAO_LOGO}
                alt={item.episode.title}
              />
              <div style={{ position: "relative" }}>
                <STitle>{item.episode.title}</STitle>
                <SMeta>
                  {item.log_time && (
                    <SMetaItem icon="time">
                      {[1].map(() => {
                        const seconds = Math.round(
                          new Date().getTime() / 1000 - item.log_time!
                        );
                        if (seconds <= 60) {
                          return "刚刚";
                        }
                        return `${Math.floor(seconds / 60)}分钟前`;
                      })}
                    </SMetaItem>
                  )}
                  {!item.log_time && item.episode.duration && (
                    <SMetaItem icon="time">{item.episode.duration}</SMetaItem>
                  )}
                  {item.user_agent && (
                    <SMetaItem icon={getIcon(item.user_agent.device_type)}>
                      {item.user_agent.app_name}
                      <span>{device && `(${device})`}</span>
                    </SMetaItem>
                  )}
                  {item.location && (
                    <SMetaItem icon="location">{item.location}</SMetaItem>
                  )}
                  {item.playcount && (
                    <SMetaItem icon="playcount">
                      {Number(item.playcount.toFixed(1)).toLocaleString()}{" "}
                      次收听
                    </SMetaItem>
                  )}
                </SMeta>
              </div>
            </SEpisode>
          );
        })}
      </SPlaylist>
      <SNote>{tip}</SNote>
    </>
  );
};

export default Playlist;
