import { Pie } from "@ant-design/charts";
import React from "react";

import { SMeta, SName, SNote, SCanvasbox } from "./style";

type Props = {
  name: string;
  tip?: string;
  data?: ResListenScene;
};

const ListenScene: React.FC<Props> = ({ name, tip, data }) => {
  if (!data) {
    return null;
  }

  const list = [
    {
      type: "在学校（教育⽹）",
      value: data.EDU,
    },
    {
      type: "在路上（使⽤⼿机⽹络）",
      value: data.MOBILE,
    },
    {
      type: "在家或在公司",
      value: data.CPN,
    },
    {
      type: "其他",
      value: data.OTHER,
    },
    {
      type: "⽹络爬⾍或服务器",
      value: data.IDC,
    },
  ];

  return (
    <>
      <SMeta>
        <SName>{name}</SName>
      </SMeta>
      <SCanvasbox>
        <div>
          <Pie
            legend={{ position: "bottom" }}
            appendPadding={5}
            data={list}
            angleField={"value"}
            colorField={"type"}
            color={["#5d3f51", "#12a182", "#2983bb", "#ffa60f", "#a6522c"]}
            radius={0.7}
            label={{
              type: "outer",
              content: "{name} {percentage}",
              offset: 15,
            }}
            tooltip={{
              formatter: (e: any) => {
                return { name: e.type, value: (e.value * 100).toFixed(2) + "%" };
              },
            }}
            animation={false}
          />
        </div>
      </SCanvasbox>
      <SNote>{tip}</SNote>
    </>
  );
};

export default ListenScene;
