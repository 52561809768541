import styled from "styled-components";

import { DEVICE } from "../../constants";

export const SMeta = styled.div`
  display: flex;
  align-items: center;
`

export const SName = styled.span`
  color: #2d9cdb;
  font-weight: bold;
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0;
`

export const SIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 14px;
`

type SListType = {
  columns?: number
}

export const SList = styled.ul<SListType>`
  list-style-type: none;
  padding-left: 0;
  padding-bottom: 10px;

  @media ${DEVICE.laptop} {
    columns: ${props => props.columns || 'unset'};
  }
`

export const SItem = styled.li`
  color: #828282;
  padding-left: 10px;
  div {
    display: flex;
    position: relative;
  }
  span:nth-child(1) {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
  }
  span:nth-child(2) {
    position: absolute;
    right: 20px;
  }
`

export const SNote = styled.span`
  color: #828282;
  font-size: 12px;
  position: absolute;
  margin-top: -30px;
  margin-right: 50px;

  @media ${DEVICE.laptop} {
    bottom: 20px;
    left: 50px;
  }
`

export const SCanvasbox = styled.div`
  box-sizing: border-box;
  height: 400px;
  margin: 4px 0;
  padding: 10px;

  @media (max-width: 1010px) {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
`
