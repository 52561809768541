import React from "react";

import { SMeta, SName, SIcon, SList, SItem, SNote } from "./style";

type Props = {
  name?: string;
  icon?: string;
  list: (LocationTop & UserAgent & ResCoreCity)[];
  columns?: number;
  tip?: string;
};

const RankingTable: React.FC<Props> = ({ name, icon, list, columns, tip }) => {
  return (
    <>
      <SMeta>
        <SName>{name}</SName>
        {icon && <SIcon src={`/icons/${icon}.svg`} alt={name} />}
      </SMeta>
      <SList columns={columns} id="text">
        {list.map((item, index) => {
          let device = item.os_type;

          if (item.device_type && item.device_type !== "Unknow") {
            device = item.device_type;
          }

          if (["Unknow", "Unknown"].includes(device!)) {
            device = "";
          }
          return (
            <SItem key={index}>
              <div>
                {item.area_name && <span>{item.area_name}</span>}
                {item.app_name && (
                  <span>
                    {item.app_name}
                    {device && `(${device})`}
                  </span>
                )}
                {item.city && <span>{item.city}</span>}
                {item.percent && (
                  <span>{(item.percent * 100).toFixed(2)}%</span>
                )}
                {item.market_share && (
                  <span>{item.market_share.toFixed(2)}</span>
                )}
              </div>
            </SItem>
          );
        })}
      </SList>
      <SNote>{tip}</SNote>
    </>
  );
};

export default RankingTable;
