import styled from "styled-components";

import { DEVICE } from "../constants";

export const SApp = styled.div`
  background: #f9f9f9;
  width: 100vw;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;

  a:link {
    text-decoration: none;
  }

  a:visited {
    color: inherit;
  }

  span.footer {
    color: #757575;
    font-size: 14px;
    text-align: center;
    margin: 20px auto 0;
    padding-bottom: 20px;
    width: 90vw;
    display: block;
    a:link {
      color: #757575;
    }
    a:hover {
      color: #d71921;
    }
  }

  @media ${DEVICE.laptop} {
    span.footer {
      text-align: center;
      width: 50vw;
    }
  }
`;

export const SBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  margin-top: 40px;
  grid-template-areas:
    "locationList"
    "ualist"
    "coreCity"
    "listenScene"
    "periodList"
    "listeningList"
    "playCountList";

  .locationList {
    grid-area: locationList;
  }

  .ualist {
    grid-area: ualist;
  }

  .coreCity {
    grid-area: coreCity;
  }

  .listenScene {
    grid-area: listenScene;
  }

  .listeningList {
    grid-area: listeningList;
  }

  .periodList {
    grid-area: periodList;
  }

  .playCountList {
    grid-area: playCountList;
  }

  @media ${DEVICE.laptop} {
    grid-template-columns: 1fr 1fr;
    padding: 0 120px;
    margin-top: 60px;
    grid-template-areas:
      "locationList ualist"
      "coreCity listenScene"
      "listeningList periodList"
      "listeningList playCountList";
  }
`;

export const SBlock = styled.div`
  background-color: #fff;
  padding: 35px 20px 35px;
  position: relative;
  @media ${DEVICE.laptop} {
    padding: 35px 50px 35px;
  }

  @media (min-width: 1025px) {
    width: 283px;
  }

  @media (min-width: 1100px) {
    width: 75%;
  }

  @media (min-width: 1300px) {
    width: 80%;
  }
`;

export const SHeader = styled.div`
  width: 100vw;
  background: url(/bg_light.jpg) no-repeat;
  background-size: cover;
  padding: 100px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

type SHeaderTipType = {
  icon?: string;
};

export const SHeaderTip = styled.span<SHeaderTipType>`
  color: #fff;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0;
  position: relative;
  padding-left: 26px;
  margin-right: 30px;
  &:before {
    content: "";
    background: url(/icons/${(props) => props.icon}.svg) no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
  }
`;

export const SHeaderCountWrapper = styled.div`
  color: #fff;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0;
  margin-top: 16px;
  opacity: 0.9;
`;

export const SHeaderCount = styled.span`
  font-weight: bold;
  font-size: 64px;
  color: #fff;
  opacity: 0.99;
`;

export const SName = styled.span`
  color: #2d9cdb;
  font-weight: bold;
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0;
`;

export const SNote = styled.span`
  color: #828282;
  font-size: 12px;
  position: absolute;

  @media ${DEVICE.laptop} {
    bottom: 20px;
    left: 50px;
  }
`;

export const SIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 14px;
  display: inline-block;
  vertical-align:top;
`;

export const SCanvasbox = styled.div`
  height: 400px;
`;
