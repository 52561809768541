export const DEFAULT_DAO_LOGO = "https://daofm.cn/wp-content/uploads/2020/07/icon-1.png"
export const DEFAULT_DAO_URL = "https://daofm.cn"
export const BASE_API = "https://apis.daopub.com/api"

const DEVICE_SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const DEVICE = {
  mobileS: `(min-width: ${DEVICE_SIZE.mobileS})`,
  mobileM: `(min-width: ${DEVICE_SIZE.mobileM})`,
  mobileL: `(min-width: ${DEVICE_SIZE.mobileL})`,
  tablet: `(min-width: ${DEVICE_SIZE.tablet})`,
  laptop: `(min-width: ${DEVICE_SIZE.laptop})`,
  laptopL: `(min-width: ${DEVICE_SIZE.laptopL})`,
  desktop: `(min-width: ${DEVICE_SIZE.desktop})`,
  desktopL: `(min-width: ${DEVICE_SIZE.desktop})`
};
