import styled from "styled-components";

import { DEVICE } from "../../constants";

export const SPlaylistName = styled.span`
  color: #2d9cdb;
  font-weight: bold;
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0;
`

export const SPlaylistIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;

  @media ${DEVICE.laptop} {
    margin-left: 14px;
  }
`

export const SPlaylistMeta = styled.div`
  display: flex;
  align-items: center;
`

export const SPlaylist = styled.div`
  margin-top: 22px;
`

export const SEpisode = styled.a`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 65px auto;
  grid-gap: 15px;
  position: relative;
  text-decoration: none;
`

export const SCover = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 4px;
`

export const STitle = styled.span`
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  &:hover {
    color: rgba(0, 0, 0, .7);
  }
`

export const SMeta = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  max-width: 100%;
`

type SMetaItemType = {
  icon?: string;
}

export const SMetaItem = styled.span<SMetaItemType>`
  color: #828282;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0;
  margin-right: 5px;
  position: relative;
  padding-left: 16px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:before {
    content: '';
    background: url(/icons/${props => props.icon}.svg) no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    width: 14px;
    height: 14px;
  }
  span {
    display: none;
  }

  @media ${DEVICE.laptop} {
    margin-right: 20px;
    span {
      display: inline;
    }
  }
`

export const SNote = styled.span`
  color: #828282;
  font-size: 12px;
  position: absolute;

  @media ${DEVICE.laptop} {
    bottom: 20px;
    left: 50px;
  }
`
