import { BASE_API } from "../constants";

const request = <T>(url: string): Promise<T> =>
  new Promise((resolve, reject) => {
    fetch(`${BASE_API}${url}`).then((res) => {
      if (res.status >= 400) {
        reject();
      } else {
        resolve(res.json());
      }
    });
  });

export const fetchListeningList = (response_hash = "") =>
  request<ResPlayingList>(`/rtd/playing_now/${response_hash}`);

export const fetchPlayCountTopList = () =>
  request<ResPlayCountTop>("/rtd/playcount_top25");

export const fetchPlayingCount = () =>
  request<ResPlayingCount>("/rtd/playing_count");

export const fetchLocationList = () =>
  request<ResLocationTop>("/rtd/location_top25");

export const fetchUATopList = () => request<ResUATop>("/rtd/ua_top25");

export const fetchListenScene = () =>
  request<ResListenScene>("/rtd/usage_type_rate");

export const fetchPeriodList = () => request<ResPeriod>("/rtd/hours_rate");

export const fetchCoreCity = () => request<ResCoreCity>("/rtd/core_city");
