import { Line } from "@ant-design/charts";
import React from "react";

import { SMeta, SName, SNote, SCanvasbox } from "./style";

type Props = {
  name: string;
  tip?: string;
  list: ResPeriod[];
};

const PeriodList: React.FC<Props> = ({ name, tip, list }) => {
  if (!list.length) {
    return null;
  }

  return (
    <>
      <SMeta>
        <SName>{name}</SName>
      </SMeta>
      <SCanvasbox>
        <Line
          appendPadding={[0, 0, 20, 0]}
          padding={[10, 20, 56, 48]}
          data={list}
          xField="hours"
          yField="rate"
          label={{ offsetX: 20, labelLine: true }}
          meta={{
            hours: {
              formatter: (e: any) => {
                return `${e}点`;
              },
            },
            rate: {
              formatter: (e: any) => {
                return `${(e * 100).toFixed(2)}%`;
              },
            },
          }}
          slider={{
            start: 0,
            end: 1,
          }}
          point={{
            size: 5,
            shape: "diamond",
            style: {
              fill: "white",
              stroke: "#5B8FF9",
              lineWidth: 2,
            },
          }}
          tooltip={{
            showMarkers: false,
            formatter: (e: any) => {
              return {
                name: `${e.hours}点`,
                value: `${(e.rate * 100).toFixed(2)}%`,
              };
            },
          }}
          state={{
            active: {
              style: {
                shadowColor: "yellow",
                shadowBlur: 4,
                stroke: "transparent",
                fill: "red",
              },
            },
          }}
          theme={{
            geometries: {
              point: {
                diamond: {
                  active: {
                    style: {
                      shadowColor: "#FCEBB9",
                      shadowBlur: 2,
                      stroke: "#F6BD16",
                    },
                  },
                },
              },
            },
          }}
          interactions={[{ type: "marker-active" }]}
          animation={false}
          autoFit={true}
        />
      </SCanvasbox>
      <SNote>{tip}</SNote>
    </>
  );
};

export default PeriodList;
